<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th>
              <InputFilter
                :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')"
                name="customer_goods_barcode"
                sort-name="customer_goods_barcode"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.sku')"
                :placeholder="$t('labels.sku')"
                name="sku"
                sort-name="sku"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.category')"
                :placeholder="$t('labels.category')"
                name="category_name"
                sort-name="category_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="name"
                sort-name="name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.goods_description')"
                :placeholder="$t('labels.goods_description')"
                name="description"
                sort-name="description"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.size')"
                :placeholder="$t('labels.size')"
                name="size"
                sort-name="size"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th v-if="customer.id_company_type == 2">
              <SelectFilter
                :label="$t('labels.vat')"
                :placeholder="$t('labels.vat')"
                :options="vatOptions"
                name="vat"
                sort-name="vat"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilterFromTo
                :label="$t('labels.price')"
                :placeholder="$t('labels.price')"
                name="price"
                sort-name="price"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilterFromTo
                :label="$t('labels.wholesale_price')"
                :placeholder="$t('labels.wholesale_price')"
                name="wholesale_price"
                sort-name="wholesale_price"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th v-if="checkPermission(['administration_goods_insurance'])">
              <InputFilterFromTo
                :label="$t('labels.insurance_value')"
                :placeholder="$t('labels.insurance_value')"
                name="insurance_value"
                sort-name="insurance_value"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in items"
            :key="`${item.id}_${item.id_warehouse}`"
            class="text-center"
          >
            <td>
              <span
                v-if="checkPermission(['administration_goods_price'])"
                class="text-decoration-underline primary--text cursor-pointer"
                @click="showUpdatePrice(item)"
              >
                {{ item.customer_goods_barcode }}
              </span>
              <span v-else>
                {{ item.customer_goods_barcode }}
              </span>
            </td>
            <td>{{ item.sku }}</td>
            <td>{{ item.category_name }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.size }}</td>
            <td v-if="customer.id_company_type == 2">
              {{ item.vat_txt }}
            </td>
            <td>
              {{ item.price !== null ? formatNumber(item.price) : "" }}
            </td>
            <td>
              {{
                item.wholesale_price !== null
                  ? formatNumber(item.wholesale_price)
                  : ""
              }}
            </td>
            <td v-if="checkPermission(['administration_goods_insurance'])">
              {{
                item.insurance_value !== null
                  ? formatNumber(item.insurance_value)
                  : ""
              }}
            </td>
            <td>
              <span
                @click="showPriceHistory(item)"
                class="text-decoration-underline primary--text cursor-pointer"
              >
                {{ $t("labels.history") }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" md="3"> </v-col>
      <v-col cols="12" md="6">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="4"
        ></v-pagination>
      </v-col>
      <v-col cols="12" md="3">
        <div class="d-flex align-center justify-end mt-1">
          <v-btn color="error" outlined @click="showDialogUpdatePriceExcel">
            {{ $t("labels.update_price_excel") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogUpdatePrice" persistent max-width="350px">
      <UpdatePrice
        v-if="dialogUpdatePrice"
        :item="updatingGoods"
        @cancel="hideUpdatePrice"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="dialogPriceHistory" max-width="800px">
      <HistoryPrice
        v-if="dialogPriceHistory"
        :item="updatingGoods"
        :customer="customer"
        @cancel="hidePriceHistory"
      />
    </v-dialog>

    <v-dialog v-model="dialogUpdatePriceExcel" persistent max-width="1024px">
      <UpdatePriceExcel
        v-if="dialogUpdatePriceExcel"
        @cancel="hideDialogUpdatePriceExcel"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { IMAGE_OPTIONS, VN_VAT_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";

export default {
  name: "GoodsPrice",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    UpdatePrice: () => import("@/components/goods/UpdatePrice"),
    UpdatePriceExcel: () => import("@/components/goods/UpdatePriceExcel"),
    HistoryPrice: () => import("@/components/goods/HistoryPrice"),
  },
  data: () => ({
    isLoading: false,
    items: [],
    page: 1,
    totalPage: 1,
    filters: {},
    sum: {},
    imageOptions: [...IMAGE_OPTIONS],
    vatOptions: [...VN_VAT_OPTIONS],
    dialogUpdatePriceExcel: false,
    dialogUpdatePrice: false,
    dialogPriceHistory: false,
    updatingGoods: {},
    customer: {},
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    const { customer } = window.me;
    this.customer = { ...customer };
    this.getList();
  },
  methods: {
    showCategoryDialog() {
      this.dialogCategory = true;
    },
    hideCategoryDialog() {
      this.dialogCategory = false;
    },
    showUpdateCategoryDialog(item) {
      this.updatingGoods = { ...item };
      this.dialogUpdateGoodsCategory = true;
    },
    hideUpdateCategoryDialog() {
      this.updatingGoods = {};
      this.dialogUpdateGoodsCategory = false;
    },
    showPriceHistory(item) {
      this.updatingGoods = { ...item };
      this.dialogPriceHistory = true;
    },
    hidePriceHistory() {
      this.updatingGoods = {};
      this.dialogPriceHistory = false;
    },

    getList: debounce(function () {
      httpClient
        .post("/goods-list-price", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows].map((row) => {
            const vat = [...this.vatOptions].find((v) => v.value == row.vat);
            return {
              ...row,
              vat_txt: (vat && vat.text) || null,
            };
          });
          this.sum = { ...data.sum };
        });
    }, 1000),

    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "hang-hoa.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-list-export",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showUpdatePrice(goods) {
      this.updatingGoods = { ...goods };
      this.dialogUpdatePrice = true;
    },
    hideUpdatePrice() {
      this.updatingGoods = {};
      this.dialogUpdatePrice = false;
    },
    showDialogUpdatePriceExcel() {
      this.dialogUpdatePriceExcel = true;
    },
    hideDialogUpdatePriceExcel() {
      this.dialogUpdatePriceExcel = false;
    },
  },
};
</script>

<style scoped></style>
